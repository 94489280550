<template>
  <div class="main">
    <HrDashboardData />
    <div class="stateChart">
      <HrDashboardStats style="width: 33%; padding-right: 25px;" />
      <div style="width: 100%">
        <!-- <HrDashboardCharts style="width: 50%" /> -->
        <HrDashboardTable style="margin-top: 20px;" />
      </div>
    </div>
  </div>
</template>

<script>
import HrDashboardData from '@/components/teams/HrDashboardData.vue'
import HrDashboardStats from '@/components/teams/HrDashboardStats.vue'
// import HrDashboardCharts from '@/components/teams/HrDashboardCharts.vue'
import HrDashboardTable from '@/components/teams/HrDashboardTable.vue'

export default {
  components: {
    HrDashboardData, HrDashboardStats, HrDashboardTable,
  },
}
</script>

<style scoped>
.main {
  padding: 80px 40px 0px 130px;
  display: flex;
  flex-direction: column;
  width: 100%
}
.stateChart {
  display: flex;
  width: 100%;
}
</style>
