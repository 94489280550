<template>
  <div class="main-content">
    <div class="card">
      <h2 id="team">
        {{ $t('Teams') }}
      </h2>
      <div class="cardContent">
        <div class="cardImg">
          <img
            src="@/assets/images/leads.svg"
            alt=""
          >
        </div>
        <div class="contentC">
          <h5>
            {{ getTeamsNames.length }}
          </h5>
        </div>
      </div>
    </div>

    <div class="card">
      <h2>{{ $t('BankHolidays') }}</h2>
      <div class="cardContent">
        <div class="cardImg">
          <img
            src="@/assets/images/leads.svg"
            alt=""
          >
        </div>
        <div class="contentC">
          <p
            v-for="holiday in getBankHolidays"
            :key="holiday.value"
          >
            {{ holiday.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      date: moment().format('YYYY-MM-DD'),
      countryStateId: '3f1b57e7-4dc1-424d-893f-08db31f6edb4',
      vacationTypes: [
        { value: 'Vacation', label: this.$t('Vacation') },
        { value: 'SickLeave', label: this.$t('AddNew19') },
        { value: 'SpecialLeave', label: this.$t('AddNew22') },
        { value: 'Appointment', label: this.$t('AddNew24') },
        { value: 'AccidentLeave', label: this.$t('AddNew20') },
        { value: 'Education', label: this.$t('AddNew21') },
        { value: 'Maternity', label: this.$t('AddNew23') },
      ],
    };
  },
  computed: {
    ...mapGetters(['getUserRole', 'getBankHolidays',
      'getEmployeesOnVacation', 'getTeamsNames']),
  },
  mounted() {
    this.fetchEmployeesOnVacation()
    this.teamNames()
    this.fetchBankHolidays()
  },
  methods: {
    ...mapActions(['getUsersRole', 'employeesOnVacationByDate',
      'teamNames', 'loadBankHolidays',
    ]),
    async fetchEmployeesOnVacation() {
      this.employeesOnVacationByDate({ date: this.date })
    },
    async fetchBankHolidays() {
      this.loadBankHolidays({ countryStateId: this.countryStateId, year: this.date })
    },
  },
}
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #333;
}

.header {
  background-color: #FF274F;
  color: white;
  padding: 20px;
}

.main-content {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0px;
  align-items: center;
}

.card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 400px;
  height: 300px;
  margin-bottom: 20px;
  /* display: flex;
  justify-content: center; */
}

.cardContent {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  gap: 20px
}

.contentC p {
  margin: 0;
}

#team {
  margin-bottom: 20%;
}

.card h2 {
  color: #FF274F;
  font-size: 22px;
  text-align: center;
  margin-bottom: 4%;
}
.scroll {
  overflow-y: scroll;
  height: 220px;
  width: 220px;
}
</style>
