<template>
  <div style="width: 100%; display: flex; gap: 35px">
    <div class="cards blue">
      <h4>{{ $t('TotalEmployees') }}</h4>
      <h1>{{ getEmployeeTotalNumber }}</h1>
    </div>
    <div class="cards purple">
      <h4>{{ $t('EmployeesOnVac') }}</h4>
      <h1>{{ getEmployeesOnVacationCounter }}</h1>
    </div>
    <div class="cards green">
      <h4>{{ $t('TotalPayroll') }}</h4>
      <h1>{{ numbersWithcomma(getDashboardSalary.salaryToPay) }}€</h1>
    </div>
    <div class="cards orange">
      <h4>{{ $t('PaidSalary') }}</h4>
      <h1>{{ numbersWithcomma(getDashboardSalary.paidSalary) }}€</h1>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      year: moment().year(),
      month: moment().format('MM'),
    };
  },
  computed: {
    ...mapGetters(['getEmployeesOnVacationCounter', 'getDashboardSalary',
      'getEmployeeTotalNumber',
    ]),
  },
  mounted() {
    this.fetchVacationCounter()
    this.fetchSalary()
    this.fetchTotalEmployeeNumber()
  },
  methods: {
    ...mapActions(['employeesVacationCounter', 'dashboardSalarySum', 'employeeTotalNumberDashboard']),
    async fetchVacationCounter() {
      this.employeesVacationCounter({
        date: moment().format('YYYY-MM-DD'),
      })
      console.log(this.getEmployeesOnVacationCounter);
    },
    async fetchSalary() {
      this.dashboardSalarySum({ year: this.year, month: this.month })
    },
    async fetchTotalEmployeeNumber() {
      this.employeeTotalNumberDashboard({ status: true })
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
  },
}
</script>

<style scoped>
.cards {
  width: 400px;
  height: 150px;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
}

.cards h4 {
  margin-bottom: 20px;
}

.blue {
  background: rgb(16,11,106);
  background: linear-gradient(90deg, rgba(16,11,106,1) 0%, rgba(55,55,149,1) 60%, rgba(53,163,185,1) 100%);
}

.purple {
  background: rgb(88,16,159);
  background: linear-gradient(38deg, rgba(88,16,159,1) 32%, rgba(63,94,251,1) 100%);
}

.green {
  background: rgb(27,106,68);
  background: linear-gradient(38deg, rgba(27,106,68,1) 44%, rgba(31,198,70,1) 100%);
}

.orange {
  background: rgb(129,67,0);
  background: linear-gradient(38deg, rgba(129,67,0,1) 53%, rgba(198,171,31,1) 100%);
}
</style>
